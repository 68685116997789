import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'

const IndexPage = ({ data }) => (
  <Layout pageTitle='Hem'>
    <oma-grid-row>
      <section className='section section--condensed page__column'>
        <p>
          Välkommen till MekoPartner/Anderssons Bilservice! Vi är en bilverkstad
          i Örbyhus utanför Tierp som utför bilservice och bilreparationer på
          alla bilmärken. Vi utför även service och reparationer på skoter.
        </p>
        <p>
          MekoPartner/Anderssons Bilservice startades 2004 och har idag två
          anställda som har lång erfarenhet från branschen. Vi är en bilverkstad
          med stort fokus på kvalitet och service. Tillsammans servar vi både
          privatpersoner och företag runt om i Tierp, Österbybruk, Månkarbo och
          Tobo med omnejd.
        </p>
        <p>
          Har du problem med din bil? Tveka inte att höra av dig! Varmt
          välkommen!
        </p>
        <GatsbyImage
          image={data.work.childImageSharp.gatsbyImageData}
          alt='Mekaniker i arbete inne i verkstaden. Arbetar med bil upphissad på bilhiss.'
        />
      </section>
      <section className='section page__column'>
        <hr className='separator' />
      </section>
      <section className='section page__column'>
        <h2 className='article__heading'>En del av MekoPartner</h2>
        <div className='meko-partner-info'>
          <p>
            Anderssons Bilservice är en MekoPartner. Detta innebär att vi
            kombinerar kunskap och engagemang med ett lågt pris. När du anlitar
            oss får du alltid en giltig stämpel i serviceboken.
          </p>
          <a href='https://www.mekopartner.se' target='_blank' rel='noreferrer'>
            <StaticImage
              src='../images/logotype-mekopartner.png'
              alt='Logotype MekoPartner'
              layout='fixed'
              width={300}
            />
          </a>
        </div>
      </section>
      <section className='page__column section'>
        <h2 className='article__heading'>Våra tjänster</h2>
        <GatsbyImage
          image={data.work2.childImageSharp.gatsbyImageData}
          alt='Anställd arbetar i verkstaden, kör med vinkelslip.'
        />
        <p>
          Bilmekaniker som erbjuder allt från bilreparationer till skoterservice
        </p>
        <p>
          MekoPartner/Anderssons Bilservice är en bilmekaniker med både kunskap
          och erfarenhet. Hos oss får bilägare av alla märken hjälp med
          bilservice och bilreparationer. Vi har även stor kunskap när det
          gäller skoter och erbjuder både service och reparationer.
        </p>
      </section>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    work: file(relativePath: { eq: "work.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 80)
      }
    }
    work2: file(relativePath: { eq: "work2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 80)
      }
    }
  }
`

export default IndexPage
